import React from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';
import { mobile } from 'styles/breakpoints';
import { Svg } from './Svg';

interface Props {
  className?: string;
  notClickable?: boolean;
  centered?: boolean;
}

const Wrapper: React.FC<Props> = React.memo(
  ({ notClickable, className, children, centered = true }) => {
    if (notClickable) {
      return (
        <Styled className={className} centered={centered}>
          {children}
        </Styled>
      );
    }

    return (
      <Link to="/">
        <Styled className={className} centered={centered}>
          {children}
        </Styled>
      </Link>
    );
  },
);

Wrapper.displayName = 'LogoWrapper';

export const Logo: React.FC<Props> = ({ ...rest }) => (
  <Wrapper {...rest}>
    <Svg
      src="icons/logo"
      width="160"
      height="52"
      viewBox="0 0 160 52"
      fill="none"
    />
  </Wrapper>
);

interface IStyled {
  centered?: boolean;
}

const Styled = styled.div<IStyled>`
  text-align: center;
  display: flex;
  justify-content: ${props => (props.centered ? 'center' : '')};
  &.centered {
    margin-left: -5rem;
    @media ${mobile} {
      margin-left: 0;
    }
  }

  &:hover {
    opacity: 1 !important;
  }
`;
