import React, { useMemo } from 'react';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';

import { images } from '../../utils/images';
import { mobile, tablet } from '../../styles/breakpoints';
import { Helmet } from 'react-helmet-async';

interface Styles {
  width?: string;
  height?: string;
  padding?: string;
  margin?: string;
  maxHeight?: string;
  maxWidth?: string;
  opacity?: string;
  borderRadius?: string;
}

export type IconsType = keyof typeof images;

interface Props extends Styles {
  src: IconsType;
  mobile_src?: IconsType;
  tablet_src?: IconsType;
  preload?: boolean;
  noLazyLoad?: boolean;
  borderRadius?: string;
}

const Img = styled.img<Styles>`
  display: block;
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  padding: ${props => props.padding || 0};
  margin: ${props => props.margin || 0};
  max-height: ${props => props.maxHeight || 'none'};
  max-width: ${props => props.maxWidth || 'none'};
  opacity: ${props => props.opacity || '1'};
  border-radius ${props => props.borderRadius || 0};
`;

export const Image: React.FC<Props> = ({
  src,
  mobile_src,
  tablet_src,
  preload,
  noLazyLoad,
  ...rest
}) => {
  const renderPicture = useMemo(
    () => (
      <picture>
        {tablet_src && images[tablet_src] ? (
          <source media={tablet} srcSet={images[tablet_src]} />
        ) : null}
        {mobile_src && images[mobile_src] ? (
          <source media={mobile} srcSet={images[mobile_src]} />
        ) : null}
        <Img src={images[src]} alt="" {...rest} />
      </picture>
    ),
    [tablet_src, mobile_src, src],
  );

  return (
    <>
      {preload && mobile_src ? (
        <Helmet
          link={[
            {
              as: 'image',
              rel: 'preload',
              href: images[mobile_src],
              media: '(max-width: 400px)',
            },
            {
              as: 'image',
              rel: 'preload',
              href: images[src],
            },
          ]}
        />
      ) : null}
      {noLazyLoad ? (
        renderPicture
      ) : (
        <LazyLoad height={rest.height || 250} once>
          {renderPicture}
        </LazyLoad>
      )}
    </>
  );
};
