import storageSession from 'redux-persist/lib/storage/session';
import * as Sentry from '@sentry/react';
import thunk, { ThunkDispatch, ThunkMiddleware } from 'redux-thunk';
import { persistReducer, persistStore } from 'redux-persist';
import { AnyAction, applyMiddleware, compose, createStore, Store } from 'redux';
import { RootStateOrAny } from 'react-redux';
import { composeWithDevTools } from 'redux-devtools-extension';

import { config, Env } from '../config';
import { rootReducer } from './reducers';
import { AppState } from './types';
import { initialFetch } from './effects';

const persistConfig = {
  key: config.API_TOKEN,
  storage: storageSession,
  blacklist: ['config'],
};

export const configStore = (preloadedState = {}) => {
  let store: Store<AppState, AnyAction>;
  const persistedReducer = persistReducer(persistConfig, rootReducer);

  const appliedMiddleware = applyMiddleware(
    thunk as ThunkMiddleware<RootStateOrAny, AnyAction>,
  );
  const enhancers = composeWithDevTools(appliedMiddleware);

  if (config.ENV === Env.DEVELOPMENT) {
    store = createStore(persistedReducer, preloadedState, enhancers);
  } else {
    const sentryReduxEnhancer = Sentry.createReduxEnhancer();

    store = createStore(
      persistedReducer,
      compose(enhancers, sentryReduxEnhancer),
    );
  }

  const persistor = persistStore(store, null, () =>
    store.dispatch(dispatch => initialFetch(dispatch)),
  );

  return { store, persistor };
};
