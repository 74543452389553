import styled from 'styled-components';

import { mobile } from 'styles/breakpoints';

interface Props {
  position?: string;
  margin?: string;
  padding?: string | null;
  mobilePadding?: string | null;
  maxWidth?: string;
}

export const Container = styled.div<Props>`
  position: ${props => props.position || 'relative'};
  display: block;
  max-width: ${props => props.maxWidth || '76.125rem'};
  padding: ${props => props.padding || '0'};
  margin: ${props => props.margin || 'auto'};

  @media (max-width: 76.125rem) {
    padding: 0 4%;
  }

  @media ${mobile} {
    padding: ${props => props.mobilePadding || '0 1rem'};
  }
`;
