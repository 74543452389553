import styled from 'styled-components';

interface Styles {
  color: string;
  margin?: string;
  fontWeight?: string | number;
  textDecoration?: string;
}

export const TextColor = styled.span<Styles>`
  margin: ${props => props.margin || '0'};
  color: ${props => props.color};
  font-weight: ${props => props.fontWeight || 'inherit'};
  text-decoration: ${props => props.textDecoration || 'none'};

  &.italic {
    font-style: italic;
  }
`;
