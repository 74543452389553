import React from 'react';
import PropTypes from 'prop-types';
import SlickSlider from 'react-slick/lib';

const SliderSectionComponent = ({ innerRef, children, ...settings }) => (
  <SlickSlider ref={innerRef} {...settings}>
    {children}
  </SlickSlider>
);

export const SliderSection = React.forwardRef((props, ref) => (
  <SliderSectionComponent innerRef={ref} {...props} />
));

SliderSectionComponent.propTypes = {
  children: PropTypes.node,
  innerRef: PropTypes.shape({
    component: PropTypes.instanceOf(React.Component),
  }),
};
