import { Experiments, Product, Question } from './types';
import { setExperiments, setProducts, setQuestions } from './actions';

import { AnyAction } from 'redux';
import { Api } from '../../apis/api';
import { ThunkDispatch } from 'redux-thunk';
import { handleError } from '../../utils/error';

export const fetchProducts = () => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) =>
  Api.fetchProducts()
    .then(products => dispatch(setProducts(products as Product[])))
    .catch(handleError);

export const fetchQuiz = () => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => {
  let quiz_name = '';

  if (typeof window !== `undefined`) {
    quiz_name =
      new URLSearchParams(window.location?.search).get('quiz') || 'main';
  } else {
    quiz_name = 'main';
  }

  Api.fetchQuiz(quiz_name)
    .then(({ questions }) => dispatch(setQuestions(questions as Question[])))
    .catch(error => {
      handleError(error);

      Api.fetchQuiz()
        .then(({ questions }) =>
          dispatch(setQuestions(questions as Question[])),
        )
        .catch(handleError);
    });
};

export const updateExperiments = () => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => {
  let experiments: Experiments = {};

  if (typeof window !== `undefined`) {
    const params = new URLSearchParams(window.location?.search);
    const testId = params.get('test');
    const flow = params.get('flow');

    if (testId) {
      experiments[testId] = true;
    }

    if (flow) {
      experiments[flow] = true;
    }
  }

  dispatch(setExperiments(experiments));
};
