import styled from 'styled-components';

interface Styles {
  alignItems?: string | null;
  justifyContent?: string | null;
  flexDirection?: string | null;
  flexWrap?: string | null;
  padding?: string | null;
  margin?: string | null;
  maxWidth?: string | null;
  minHeight?: string | null;
  width?: string;
  height?: string;
  bg?: string;
  flex?: string;
  position?: string;
}

export const FlexWrapper = styled.div<Styles>`
  display: flex;
  align-items: ${props => props.alignItems || 'stretch'};
  justify-content: ${props => props.justifyContent || 'flex-start'};
  flex-direction: ${props => props.flexDirection || ''};
  flex-wrap: ${props => props.flexWrap || ''};
  max-width: ${props => props.maxWidth || ''};
  min-height: ${props => props.minHeight || ''};
  width: ${props => props.width || '100%'};
  height: ${props => props.height || 'auto'};
  padding: ${props => props.padding || 0};
  margin: ${props => props.margin || 0};
  flex: ${props => props.flex || ''};
  position: ${props => props.position || ''};
`;
