import { navigate } from 'gatsby';
import { useLocation } from '@reach/router';

export const useRouter = () => {
  const { pathname } = useLocation();
  const path = /\/$/.test(pathname) ? pathname.slice(0, -1) : pathname;

  return {
    goToLanding: () => navigate('/'),
    goToQuiz: (params?: string) =>
      navigate(`/quiz${typeof params === 'string' ? params : ''}`),
    goToSummary: () => navigate('/summary'),
    goToCheckout: () => navigate('/checkout'),
    goToPayments: () => navigate('/payments'),
    goToSuccess: () => navigate('/success'),
    goToOutOfStock: () => navigate('/out-of-stock'),
    goToEmail: () => navigate('/email'),
    goToFaq: () => navigate('/faq'),
    closeModal: () =>
      navigate(path.replace(/\/payments/, '').replace(/\/shipping/, '')),
  };
};
