import styled from 'styled-components';

import { black } from '../../styles/colors';

export const BodyWrapper = styled.div`
  position: relative;
  min-height: 100vh;
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  font-weight: 400;
  color: ${black};
  overflow: hidden auto;

  a {
    text-decoration: none;
    transition: opacity 0.1;

    &:hover {
      opacity: 0.8;
    }

    &:any-link {
      color: inherit;
    }
  }
`;
