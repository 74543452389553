// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-checkout-2-index-tsx": () => import("./../../../src/pages/checkout-2/index.tsx" /* webpackChunkName: "component---src-pages-checkout-2-index-tsx" */),
  "component---src-pages-checkout-3-index-tsx": () => import("./../../../src/pages/checkout-3/index.tsx" /* webpackChunkName: "component---src-pages-checkout-3-index-tsx" */),
  "component---src-pages-checkout-index-tsx": () => import("./../../../src/pages/checkout/index.tsx" /* webpackChunkName: "component---src-pages-checkout-index-tsx" */),
  "component---src-pages-contacts-tsx": () => import("./../../../src/pages/contacts.tsx" /* webpackChunkName: "component---src-pages-contacts-tsx" */),
  "component---src-pages-email-tsx": () => import("./../../../src/pages/email.tsx" /* webpackChunkName: "component---src-pages-email-tsx" */),
  "component---src-pages-faq-tsx": () => import("./../../../src/pages/faq.tsx" /* webpackChunkName: "component---src-pages-faq-tsx" */),
  "component---src-pages-home-index-tsx": () => import("./../../../src/pages/home/index.tsx" /* webpackChunkName: "component---src-pages-home-index-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-out-of-stock-tsx": () => import("./../../../src/pages/out-of-stock.tsx" /* webpackChunkName: "component---src-pages-out-of-stock-tsx" */),
  "component---src-pages-payments-index-tsx": () => import("./../../../src/pages/payments/index.tsx" /* webpackChunkName: "component---src-pages-payments-index-tsx" */),
  "component---src-pages-privacy-policy-tsx": () => import("./../../../src/pages/privacy-policy.tsx" /* webpackChunkName: "component---src-pages-privacy-policy-tsx" */),
  "component---src-pages-quiz-index-tsx": () => import("./../../../src/pages/quiz/index.tsx" /* webpackChunkName: "component---src-pages-quiz-index-tsx" */),
  "component---src-pages-success-tsx": () => import("./../../../src/pages/success.tsx" /* webpackChunkName: "component---src-pages-success-tsx" */),
  "component---src-pages-summary-2-index-tsx": () => import("./../../../src/pages/summary-2/index.tsx" /* webpackChunkName: "component---src-pages-summary-2-index-tsx" */),
  "component---src-pages-summary-3-index-tsx": () => import("./../../../src/pages/summary-3/index.tsx" /* webpackChunkName: "component---src-pages-summary-3-index-tsx" */),
  "component---src-pages-summary-index-tsx": () => import("./../../../src/pages/summary/index.tsx" /* webpackChunkName: "component---src-pages-summary-index-tsx" */),
  "component---src-pages-terms-of-services-tsx": () => import("./../../../src/pages/terms-of-services.tsx" /* webpackChunkName: "component---src-pages-terms-of-services-tsx" */),
  "component---src-pages-upsell-index-tsx": () => import("./../../../src/pages/upsell/index.tsx" /* webpackChunkName: "component---src-pages-upsell-index-tsx" */)
}

