import styled from 'styled-components';

import { text } from 'styles/colors';
import { mobile } from 'styles/breakpoints';

interface Styles {
  margin?: string;
  padding?: string;
  fontSize?: string;
  fontWeight?: string | number;
  lineHeight?: string;
  color?: string;
  textAlign?: string;
  mobileFontSize?: string;
  mobileLineHeight?: string;
}

export const H3 = styled.h3<Styles>`
  margin: ${props => props.margin || '0px'};
  padding: ${props => props.padding || '0px'};
  font-family: ivypresto-headline, serif;
  font-size: ${props => props.fontSize || '2rem'};
  font-weight: ${props => props.fontWeight || 600};
  line-height: ${props => props.lineHeight || '2.875rem'};
  color: ${props => props.color || text};
  text-align: ${props => props.textAlign || 'inherit'};

  @media ${mobile} {
    font-size: ${props => props.mobileFontSize || '1.5rem'};
    line-height: ${props => props.mobileLineHeight || '1.875rem'};
  }
`;
