import styled from 'styled-components';
import { mobile } from 'styles/breakpoints';

import { text } from 'styles/colors';

interface Styles {
  display?: string;
  margin?: string;
  maxWidth?: string;
  padding?: string | null;
  fontSize?: string;
  fontWeight?: string | number;
  lineHeight?: string;
  color?: string;
  textAlign?: string | null;
  mobileFontSize?: string;
  mobileLineHeight?: string;
}

export const Regular = styled.p<Styles>`
  display: ${props => props.display || ''};
  margin: ${props => props.margin || '0px'};
  max-width: ${props => props.maxWidth || 'none'};
  padding: ${props => props.padding || '0px'};
  font-family: acumin-pro, sans-serif;
  font-size: ${props => props.fontSize || '1rem'};
  font-weight: ${props => props.fontWeight || 'normal'};
  line-height: ${props => props.lineHeight || '1.6875rem'};
  color: ${props => props.color || text};
  text-align: ${props => props.textAlign || 'inherit'};

  @media ${mobile} {
    font-size: ${props => props.mobileFontSize || '1rem'};
    line-height: ${props => props.mobileLineHeight || '1.6875rem'};
  }
`;
