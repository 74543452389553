import { createReducer, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';

import { setExperiments, setProducts, setQuestions } from './actions';
import { IFunnelState } from './types';

const products = createReducer<IFunnelState['products']>(
  [],
  reduce(setProducts, set),
);
const questions = createReducer<IFunnelState['questions']>(
  [],
  reduce(setQuestions, set),
);
const experiments = createReducer<IFunnelState['experiments']>(
  null,
  reduce(setExperiments, set),
);

export default combineReducers<IFunnelState>({
  products,
  questions,
  experiments,
});
