import styled from 'styled-components';

import { Button } from './Button';
import { blue, white } from 'styles/colors';

export const PrimaryButton = styled(Button)`
  color: ${white};
  background: ${blue};

  &:hover {
    background: #044560;
  }
`;
