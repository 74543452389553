import { useMemo } from 'react';

export const useSearchParams = (name: string) =>
  useMemo(() => {
    if (typeof window !== `undefined`) {
      const params = new URLSearchParams(window.location.search);
      return params.get(name);
    }
    return null;
  }, [name]);
