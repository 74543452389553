import styled from 'styled-components';

import { text } from 'styles/colors';
import { mobile } from 'styles/breakpoints';
import { fadeIn } from 'styles/animations';

interface Styles {
  padding?: string;
  margin?: string;
  fontSize?: string;
  fontWeight?: string | number;
  lineHeight?: string;
  color?: string;
  textAlign?: string | null;
  mobileFontSize?: string;
  mobileLineHeight?: string;
}

export const H2 = styled.h2<Styles>`
  padding: ${props => props.padding || '0px'};
  margin: ${props => props.margin || '0px'};
  font-family: ivypresto-headline, serif;
  font-size: ${props => props.fontSize || '2.625rem'};
  font-weight: ${props => props.fontWeight || 600};
  line-height: ${props => props.lineHeight || '3.375rem'};
  color: ${props => props.color || text};
  text-align: ${props => props.textAlign || 'inherit'};

  &.fadeIn {
    opacity: 0;
    animation: ${fadeIn} 0.2s ease-in forwards;
  }

  @media ${mobile} {
    font-size: ${props => props.mobileFontSize || '2.125rem'};
    line-height: ${props => props.mobileLineHeight || '2.75rem'};
  }
`;
