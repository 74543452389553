import React from 'react';
import styled from 'styled-components';
import { mobile } from 'styles/breakpoints';
import { Accordion } from 'components';
import { Regular } from 'components/text/Regular';

const FAQ_ITEMS = [
  {
    title: 'What is Pulsio Box made of? What is the active ingredient?',
    body:
      'Pulsio is the only powder nutritional supplement that contains clinically beneficial levels of CoQ10, Nattokinase, and Vitamin K2, together with other cardiovascular supportive elements for cardiovascular health. Research studies show that these active ingredients can help reduce cardiovascular-related risks.',
  },
  {
    title: 'Do I need a prescription to take Pulsio?',
    body: 'No, you don\'t need a prescription to get Pulsio.',
  },
  {
    title:
      'Why should I take Pulsio instead of taking these ingredients/supplements separately?',
    body:
      'Pulsio removes the guesswork from taking cardiovascular supplements. Pulsio makes it simple to receive the nutrients you need to treat cardiovascular diseases naturally by combining clinically proven doses of CoQ10, Vitamin K2, Nattokinase, and Guggul gum into one convenient daily serving.',
  },
  {
    title: 'Does Pulsio have any side effects?',
    body:
      'Side effects are a possibility with any treatment, but Pulsio\'s active components (CoQ10, Vitamin K2, and Nattokinase) have been demonstrated to have low side effects. Bloating, diarrhea, nausea, and stomach cramps are all examples of these symptoms. Vitamin K2 can cause urine to turn a bright yellow color, but this usually isn\'t a cause for concern. It\'s advisable to see your doctor before starting Pulsio if you are concerned about specific side effects.',
  },
  {
    title: 'Can I take Pulsio with other medications I\'m taking?',
    body:
      'Pulsio\'s active ingredients (CoQ10, Vitamin K2, and Nattokinase) can be taken alongside most medications. If you have any questions, it\'s best to talk to your doctor before starting Pulsio.',
  },
  {
    title: 'Can I mix Pulsio with other liquids than water?',
    body:
      'Yes, you can mix Pulsio with other liquids as long as you drink the entire amount of 8-10 ounces. Whether you don\'t think you\'ll finish a drink, don\'t add Pulsio to it.',
  },
  {
    title:
      'Can I take Pulsio over the day, or do I need to take it all at once?',
    body:
      'For best results, drink 8-10 ounces of liquid mixed with Pulsio every day. While you don\'t have to drink the entire portion at once, make sure you plan to finish it later.',
  },
  {
    title: 'How to take Pulsio?',
    body:
      'Pulsio Box consists of 30 sachets. Take one sachet daily before meal, mix it with a glass of water, stir before drinking.',
  },
  {
    title: 'What is Sachet?',
    body:
      'A single-use packet of any material in a compact, sealed packet. Our proprietary formula powder is contained in the Pulsio sachet.',
  },
  {
    title: 'How many can I take?',
    body: 'It is recommended not to exceed two sachets per 24 hours. ',
  },
  {
    title: 'What is your money-back guarantee?',
    body:
      'We stand behind all our treatments and are confident that they will perform as designed. However, if you are not 100% satisfied with one of our kits, contact the support team hi@pulsio.me to request a full refund within 30 days of purchase.',
  },
  {
    title: 'Can you take Pulsio while pregnant or breastfeeding?',
    body:
      'Do not take Pulsio if you are pregnant, trying to conceive, or breastfeeding before consulting with your doctor or personal obstetrician.',
  },
  {
    title: 'When will I feel the effects of Pulsio?',
    body:
      'The Pulsio products are made of natural ingredients that need up to two weeks to start showing effect. However, most of our customers start feeling the effect in the first week of taking Pulsio.',
  },
  {
    title: 'Is Pulsio FDA-approved?',
    body:
      'The FDA does not manage supplements or over-the-counter cardiovascular medications, though every Pulsio treatment plan is GMP-certified. Each treatment we offer has gone through clinical tests and is approved for following the industry best practices and manufacturing standards ensuring that we comply and exceed the GMP regulations for treating cardiovascular diseases.',
  },
];

export const FaqList: React.FC = React.memo(() => (
  <FaqWrapper>
    {FAQ_ITEMS.map(({ title, body }) => (
      <Accordion key={title.replace(' ', '_')} title={title}>
        <AccordionBody
          padding="0 1.5rem 1.5rem"
          dangerouslySetInnerHTML={{ __html: body }}
        />
      </Accordion>
    ))}
  </FaqWrapper>
));

FaqList.displayName = 'FaqList';

const FaqWrapper = styled.div`
  position: relative;
`;
const AccordionBody = styled(Regular)`
  max-width: 42rem;
  @media ${mobile} {
    max-width: 20.438rem;
  }
`;
