import React from 'react';
import styled from 'styled-components';

import { background, primary } from '../../styles/colors';

interface Props {
  step: number;
  totalSteps: number;
}

export const ProgressBar: React.FC<Props> = ({ step, totalSteps }) => {
  const progress = (step * 100) / totalSteps;

  return (
    <Container>
      <ProgressBorder>
        <Progress width={progress.toFixed(2)} />
      </ProgressBorder>
    </Container>
  );
};

const Container = styled.div`
  width: 100%;
  margin-bottom: 1rem;
`;

const ProgressBorder = styled.div`
  display: block;
  height: 0.3125rem;
  margin: auto;
  background: ${background};
`;

const Progress = styled.div<{ width: string }>`
  width: ${props => props.width || '0'}%;
  height: 0.3125rem;
  background: ${primary};
  transition: width 0.2s;
`;
