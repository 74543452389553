import logo from '../assets/images/logo.png';

/** home */
import pulsio_box from '../assets/images/home/pulsio_box.png';
import pulsio_box_with_circle from '../assets/images/home/pulsio_box_with_circle.png';
import pulsio_box_with_circle_mobile from '../assets/images/home/pulsio_box_with_circle_mobile.png';
import hero_background from '../assets/images/home/hero_background.png';
import online_treatment_1 from '../assets/images/home/online_treatment_1.png';
import online_treatment_2 from '../assets/images/home/online_treatment_2.png';
import online_treatment_3 from '../assets/images/home/online_treatment_3.png';
import person_looking from '../assets/images/home/person_looking.png';
import does_it_work_woman from '../assets/images/home/does_it_work_woman.png';
import gel from '../assets/images/home/gel.png';
import testimonials_background from '../assets/images/home/testimonials_background.png';
import reviewer_1 from '../assets/images/home/reviewer_1.png';
import reviewer_2 from '../assets/images/home/reviewer_2.png';
import reviewer_3 from '../assets/images/home/reviewer_3.png';
import reviewer_4 from '../assets/images/home/reviewer_4.png';
import reviewer_5 from '../assets/images/home/reviewer_5.png';
import reviewer_6 from '../assets/images/home/reviewer_6.png';
import reviewer_7 from '../assets/images/home/reviewer_7.png';
import reviewer_8 from '../assets/images/home/reviewer_8.png';
import reviewer_9 from '../assets/images/home/reviewer_9.png';
import reviewer_10 from '../assets/images/home/reviewer_10.png';

import pill from '../assets/images/home/pill.gif';
/** quiz */
import person_chopping_veggies from '../assets/images/quiz/person_chopping_veggies.png';
import cupcakes from '../assets/images/quiz/cupcakes.png';
import salt from '../assets/images/quiz/salt.png';
import person_chopping_veggies_mobile from '../assets/images/quiz/person_chopping_veggies_mobile.png';
import cupcakes_mobile from '../assets/images/quiz/cupcakes_mobile.png';
import salt_mobile from '../assets/images/quiz/salt_mobile.png';

/** summary */
import summary_hero_background from '../assets/images/summary/summary_hero_background.png';
import our_promise_background from '../assets/images/summary/our_promise_background.png';
import doctor_portrait from '../assets/images/summary/doctor_portrait.png';
import doctor_mobile from '../assets/images/summary/doctor_mobile.png';
import pulsio_6_boxes from '../assets/images/summary/pulsio_6_boxes.png';
import signature from '../assets/images/summary/signature.png';
import cycle from '../assets/images/summary/cycle.png';
import stock_up from '../assets/images/summary/stock_up.png';
import scales from '../assets/images/summary/scales.png';
import man_emoji from '../assets/images/summary/man_emoji.png';
import woman_emoji from '../assets/images/summary/woman_emoji.png';

/** checkout */
import checkout_hero_background from '../assets/images/checkout/checkout_hero_background.png';
import rapid_relief_background from '../assets/images/checkout/rapid_relief_background.png';
import pulsio_pack_circle from '../assets/images/checkout/pulsio_pack_circle.png';
import woman_smilling from '../assets/images/checkout/woman_smilling.png';
import woman_with_glasses from '../assets/images/checkout/woman_with_glasses.png';
import product_1_month from '../assets/images/checkout/product_1_month.png';
import product_3_month from '../assets/images/checkout/product_3_month.png';
import product_6_month from '../assets/images/checkout/product_6_month.png';
import payments from '../assets/images/checkout/payments.png';
import pulsio_box_opened from '../assets/images/checkout/pulsio_box_opened.png';
import pulsio_box_opened_mobile from '../assets/images/checkout/pulsio_box_opened_mobile.png';
import pulsio_supplement from '../assets/images/checkout/pulsio_supplement.png';
import popular_supplement from '../assets/images/checkout/popular_supplement.png';
import made_in_USA from '../assets/images/checkout/made_in_USA.png';
import curve from '../assets/images/checkout/curve.png';
import fenugreek_seed from '../assets/images/checkout/fenugreek_seed.png';
import nattokinase from '../assets/images/checkout/nattokinase.png';
import vitamin_k2 from '../assets/images/checkout/vitamin_k2.png';
import beetroot_extract from '../assets/images/checkout/beetroot_extract.png';
import coenzyme_q10 from '../assets/images/checkout/coenzyme_q10.png';
import grapeseed from '../assets/images/checkout/grapeseed.png';
import dietary_fiber from '../assets/images/checkout/dietary_fiber.png';
import guggul_gum from '../assets/images/checkout/guggul_gum.png';
import pulsio_box_with_orange_drink from '../assets/images/checkout/pulsio_box_with_orange_drink.png';
import supplement_facts from '../assets/images/checkout/supplement_facts.png';
import supplement_facts_mobile from '../assets/images/checkout/supplement_facts_mobile.png';
import ruth_before_and_after from '../assets/images/checkout/ruth_before_and_after.png';
import bg_lines from '../assets/images/checkout/bg_lines.png';
import checkmark_product from '../assets/icons/checkmark_product.png';

/** contacts */
import man_smiling from '../assets/images/contacts/man_smiling.png';

/** other */
import business_insider from '../assets/images/other/business_insider.png';
import harvard_medical_school from '../assets/images/other/harvard_medical_school.png';
import healthline from '../assets/images/other/healthline.png';
import mens_health from '../assets/images/other/mens_health.png';
import ny_times from '../assets/images/other/ny_times.png';
import line404_mobile from '../assets/images/other/line404_mobile.png';
import line404_dekstop from '../assets/images/other/line404_dekstop.png';

export const images: Record<string, string> = {
  logo,
  // home
  pulsio_box,
  pulsio_box_with_circle,
  pulsio_box_with_circle_mobile,
  hero_background,
  online_treatment_1,
  online_treatment_2,
  online_treatment_3,
  person_looking,
  does_it_work_woman,
  gel,
  testimonials_background,
  reviewer_1,
  reviewer_2,
  reviewer_3,
  reviewer_4,
  reviewer_5,
  reviewer_6,
  reviewer_7,
  reviewer_8,
  reviewer_9,
  reviewer_10,
  made_in_USA,
  curve,

  pill,
  // quiz
  person_chopping_veggies,
  cupcakes,
  salt,
  person_chopping_veggies_mobile,
  cupcakes_mobile,
  salt_mobile,
  // summary
  summary_hero_background,
  our_promise_background,
  doctor_portrait,
  doctor_mobile,
  pulsio_6_boxes,
  signature,
  cycle,
  stock_up,
  scales,
  man_emoji,
  woman_emoji,
  // checkout
  checkout_hero_background,
  rapid_relief_background,
  pulsio_pack_circle,
  woman_smilling,
  woman_with_glasses,
  product_1_month,
  product_3_month,
  product_6_month,
  payments,
  pulsio_box_opened,
  pulsio_box_opened_mobile,
  pulsio_supplement,
  popular_supplement,
  fenugreek_seed,
  nattokinase,
  vitamin_k2,
  beetroot_extract,
  coenzyme_q10,
  grapeseed,
  dietary_fiber,
  guggul_gum,
  pulsio_box_with_orange_drink,
  supplement_facts,
  supplement_facts_mobile,
  ruth_before_and_after,
  bg_lines,
  checkmark_product,
  // contacts
  man_smiling,
  // other
  business_insider,
  harvard_medical_school,
  healthline,
  mens_health,
  ny_times,
  line404_mobile,
  line404_dekstop,
};
