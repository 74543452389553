import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { each, get } from 'lodash';
import { useSelector } from 'react-redux';

export const KiloPayments = ({
  code,
  children,
  handleError,
  handleSubmit,
  handleSuccess,
  initialProduct,
}) => {
  let IS_LOADED = useRef(false);
  const [loading, setLoading] = useState(false);
  const [loaded, setLoaded] = useState(false);
  const urlConfigs = useSelector(s => s.config?.gateway);
  const loadScript = useCallback(
    src =>
      new Promise(resolve => {
        if (IS_LOADED.current) {
          resolve();

          return;
        }

        const element = document.createElement('script');

        const attributes = {
          id: 'kilo-script-' + btoa(src),
          src,
        };

        each(attributes, (value, name) => {
          if (value) {
            element[name] = value;
          }
        });

        element.onload = function () {
          IS_LOADED.current = true;
          resolve();
        };

        document['head'].appendChild(element);
      }),
    [],
  );

  const onSuccess = useCallback(
    data => {
      if (typeof handleSuccess === 'function') {
        handleSuccess(data);
      }

      return false;
    },
    [handleSuccess],
  );

  const onSubmit = useCallback(
    data => {
      if (typeof handleSubmit === 'function') {
        handleSubmit(data);
      }
    },
    [handleSubmit],
  );

  const onError = useCallback(
    e => {
      if (typeof handleError === 'function') {
        handleError(e);
      }
      return false;
    },
    [handleError],
  );

  const createUi = useCallback(async () => {
    try {
      setLoading(true);
      const [accProvider, cardProvider] = Object.values(
        urlConfigs.prepare_urls,
      );

      const price = get(initialProduct, 'prices[0].final_price');
      const product = new window.KiloProduct(
        initialProduct.key,
        initialProduct.name,
        price,
      );

      await window.kiloCheckout.create(accProvider, {
        product,
        clientCode: code,
        selector: '#kilo-payment',
        callbacks: {
          onError,
          onSubmit,
          onSuccess,
        },
      });

      await window.kiloCheckout.create(cardProvider, {
        product,
        clientCode: code,
        selector: '#kilo-payment-card',
        callbacks: {
          onError,
          onSubmit,
          onSuccess,
        },
      });
      setLoaded(true);
    } catch (e) {
      onError(e);
    } finally {
      setLoading(false);
    }
  }, [code, initialProduct, onError, onSubmit, onSuccess, urlConfigs]);

  useEffect(() => {
    if (urlConfigs?.main) {
      loadScript(urlConfigs.main).then(() => {
        createUi();
      });
    }
  }, [urlConfigs, createUi, loadScript]);

  return (
    <>
      <div id="kilo-payment" />
      <div id="kilo-payment-card" />
      {loading ? children : null}
      <div id="kilo-payments-error" />
    </>
  );
};

KiloPayments.propTypes = {
  children: PropTypes.any,
  code: PropTypes.string,
  handleError: PropTypes.func,
  handleSubmit: PropTypes.func,
  handleSuccess: PropTypes.func,
  initialProduct: PropTypes.object,
};
