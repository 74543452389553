import React from 'react';
import styled from 'styled-components';

import { black, white } from '../../styles/colors';

interface Props extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  isLoading?: boolean;
  maxWidth?: string;
  margin?: string;
  padding?: string;
  flex?: string;
  onClick?: () => void;
}

export const Button: React.FC<Props> = ({
  onClick,
  children,
  isLoading,
  ...rest
}) => (
  <Container onClick={onClick} disabled={isLoading} {...rest}>
    {isLoading ? 'Please wait...' : children}
  </Container>
);

const Container = styled.button<Props>`
  display: block;
  min-width: ${props => props.maxWidth || '12rem'};
  margin: ${props => props.margin || ''};
  flex: ${props => props.flex || ''};
  padding: ${props => props.padding || '1.05rem 2.25rem'};
  font-family: acumin-pro, sans-serif;
  font-size: 0.8125rem;
  color: ${white};
  background: ${black};
  border: none;
  border-radius: 2.5rem;
  transition: all 0.2s;
  cursor: pointer;

  :disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }
`;
