import styled from 'styled-components';
import { blue } from 'styles/colors';

import { Button } from './Button';

export const TextButton = styled(Button)`
  background-color: transparent;
  text-align: center;
  text-transform: uppercase;
  color: ${blue};

  &:hover {
    background-color: transparent;
    box-shadow: none;
  }

  svg {
    margin-right: 10px;
  }
`;
