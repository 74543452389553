import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import { fetchProjectConfig } from './config/effects';
import { fetchProducts, fetchQuiz, updateExperiments } from './funnel/effects';
import { fetchUser } from './user/effects';

export const initialFetch = dispatch => {
  dispatch(fetchProjectConfig());
  dispatch(fetchProducts());
  dispatch(fetchQuiz());
  dispatch(fetchUser());
  dispatch(updateExperiments());
};
