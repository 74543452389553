import styled from 'styled-components';

import { primary, white } from 'styles/colors';

import { Button } from './Button';

export const SecondaryButton = styled(Button)`
  color: ${white};
  background: ${primary};

  &:hover {
    background: #e85745;
  }
`;
