import React, { StrictMode } from 'react';
import { ParallaxProvider } from 'react-scroll-parallax';

import { Provider } from 'react-redux';
import { configStore } from 'state/store';
import { BodyWrapper, withApp } from 'components';
import { Footers, Modals, Navigations } from 'layouts';
import { PersistGate } from 'redux-persist/integration/react';

const { store, persistor } = configStore();

export const wrapRootElement = ({ element }: { element: any }) => {
  if (typeof window === 'undefined') {
    return (
      <StrictMode>
        <Provider store={store}>
          <ParallaxProvider>
            <BodyWrapper>
              <Navigations />
              {withApp(element)}
              <Modals />
              <Footers />
            </BodyWrapper>
          </ParallaxProvider>
        </Provider>
      </StrictMode>
    );
  }
  return (
    <StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          <ParallaxProvider>
            <BodyWrapper>
              <Navigations />
              {withApp(element)}
              <Modals />
              <Footers />
            </BodyWrapper>
          </ParallaxProvider>
        </PersistGate>
      </Provider>
    </StrictMode>
  );
};
