import { AnyAction, combineReducers } from 'redux';

import funnelReducer from './funnel/reducer';
import configReducer from './config/reducer';
import userReducer from './user/reducer';

const combinedReducer = combineReducers({
  funnel: funnelReducer,
  config: configReducer,
  user: userReducer,
});

export const rootReducer = (state: any, action: AnyAction) =>
  combinedReducer(state, action);
