export enum Env {
  PRODUCTION = 'production',
  DEVELOPMENT = 'development',
}

const NAME = 'Pulsio';
const ENV =
  process.env.NODE_ENV === Env.PRODUCTION ? Env.PRODUCTION : Env.DEVELOPMENT;
const GTM_TAG = process.env.REACT_APP_GTM_TAG || 'GTM-KS8848J';
const API_TOKEN =
  process.env.REACT_APP_API_TOKEN || '9f52a55e-9286-469d-912f-2ce94b98591b';
const BASE_URL =
  process.env.REACT_APP_API_URL || 'https://rnd.kilohealthservices.com/api/';
const SENTRY_DNS =
  process.env.REACT_APP_SENTRY_DNS ||
  'https://69d5103b345f488da20205d1d93f40bb@o153093.ingest.sentry.io/5411566';

const configs = {
  [Env.DEVELOPMENT]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
  },
  [Env.PRODUCTION]: {
    ENV,
    NAME,
    GTM_TAG,
    BASE_URL,
    API_TOKEN,
    SENTRY_DNS,
  },
};

export const config = configs[ENV];
