import React from 'react';
import styled from 'styled-components';

import { background, white } from '../../styles/colors';
import { mobile } from '../../styles/breakpoints';
import { Container } from '..';

export const BackgroundWrapper: React.FC = ({ children }) => (
  <Background>
    <Container>
      <ModalView>{children}</ModalView>
    </Container>
  </Background>
);
const Background = styled.div`
  padding: 4.5rem 0;
  min-height: calc(100vh - 85px - 9rem);
  background: ${background};

  @media ${mobile} {
    min-height: 100vh;
    padding: 3rem 0;
  }
`;

const ModalView = styled.div`
  position: relative;
  min-height: 520px;
  padding: 0 4%;
  margin: 0 auto;
  text-align: center;
  background: ${white};
  border-radius: 6px;
  overflow: hidden;

  @media ${mobile} {
    padding: 0 2%;
  }
`;
