import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Loader } from '.';
import { AppState } from 'state/types';
import { fetchUser } from 'state/user/effects';
import { useLocation } from '@reach/router';
import { useRouter } from 'apis/history';
import { useSearchParams } from 'hooks/use-search-params';

interface Props {
  redirectTo?: () => void;
  userStateKey?: string;
}

export const PrivateRoute: React.FC<Props> = React.memo(
  ({ children, redirectTo, userStateKey }) => {
    const dispatch = useDispatch();
    const { goToLanding } = useRouter();

    const { pathname } = useLocation();
    const { code } = useSelector((s: AppState) => s.user);
    const userData = useSelector((s: AppState) => s.user.quiz_answers);

    const codeParam = pathname.split('/')?.pop();
    const pathCode = useSearchParams('code') || codeParam;

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      const fetchUserData = async () => {
        try {
          await dispatch(fetchUser());
          setIsLoading(false);
        } catch (error) {
          if (redirectTo) {
            redirectTo();
          } else {
            goToLanding();
          }
        }
      };

      if (pathCode && code !== pathCode) {
        fetchUserData();
      } else {
        setIsLoading(false);
      }
    }, []);

    useEffect(() => {
      if (userStateKey && !userData[userStateKey]) {
        if (redirectTo) {
          redirectTo();
        } else {
          goToLanding();
        }
      }
    }, [userData]);

    if (isLoading) {
      return <Loader />;
    }

    return <>{children}</>;
  },
);

PrivateRoute.displayName = 'Private Route';
