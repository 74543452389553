import React, { useState } from 'react';
import MailCheck from 'react-mailcheck';
import styled from 'styled-components';
import { navigate } from 'gatsby';

import { FlexWrapper, OutlineButton, Svg } from 'components';
import { blue, error } from 'styles/colors';
import { mobile, useQuery } from 'styles/breakpoints';
import { validateEmail } from 'utils/validations';
import { Tiny } from 'components/text/Tiny';
import { TextButton } from 'components/buttons/TextButton';
import { Regular } from 'components/text/Regular';
import { Input } from 'components/inputs/Input';

interface Props {
  errorMessage: string;
  onSubmit: (email: string) => void;
  isDisabled: boolean;
}

export const EmailForm: React.FC<Props> = ({
  errorMessage,
  onSubmit,
  isDisabled,
}) => {
  const [email, setEmail] = useState('');
  const { isMobile } = useQuery();

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const emailAddress = email.replace(/\s/g, '');

    onSubmit(emailAddress);
  };

  const onTextUpdate = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
  };

  return (
    <Form onSubmit={handleSubmit}>
      {errorMessage && (
        <Regular margin="0 0 1rem 0" color={error}>
          {errorMessage}
        </Regular>
      )}
      <MailCheck email={email}>
        {(suggestion: any) => (
          <>
            <Input
              placeholder="Type your email here..."
              onChange={onTextUpdate}
              value={email}
              type="email"
              name="email"
            />
            {suggestion && validateEmail(email) ? (
              <RegularCursor
                margin="0 0 1rem 0"
                onClick={() => setEmail(suggestion.full)}
                textAlign="center"
              >
                Did you mean:{' '}
                <Regular fontWeight={700}>{suggestion.full}</Regular>?
              </RegularCursor>
            ) : null}
          </>
        )}
      </MailCheck>
      <StyledTiny textAlign="center" color={blue} fontSize="0.75rem">
        We don’t send spam or share email addresses. We respect your privacy.
      </StyledTiny>

      <FlexWrapper
        justifyContent="center"
        margin="3.8rem 0"
        alignItems="center"
        flexDirection={isMobile ? 'column' : 'row'}
      >
        <BackButton maxWidth="12rem" onClick={() => navigate(-1)}>
          <Svg
            src="icons/arrow_left"
            width="11"
            height="9"
            viewBox="0 0 11 9"
            fill="none"
          />
          BACK
        </BackButton>
        <NextButton
          maxWidth="11.25rem"
          margin="0 0 0 1rem"
          type="submit"
          isLoading={isDisabled}
        >
          NEXT
        </NextButton>
      </FlexWrapper>
    </Form>
  );
};

const Form = styled.form`
  max-width: 24.125rem;
  margin: auto;
  @media ${mobile} {
    max-width: 20.438rem;
  }
`;
const StyledTiny = styled(Tiny)`
  opacity: 0.5;
`;
const BackButton = styled(TextButton)`
  @media ${mobile} {
    order: 2;
  }
`;
const NextButton = styled(OutlineButton)`
  @media ${mobile} {
    order: 1;
  }
`;
const RegularCursor = styled(Regular)`
  cursor: pointer;
`;
