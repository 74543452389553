import React from 'react';
import styled from 'styled-components';

import { mobile, useQuery } from 'styles/breakpoints';
import { background, blue, blue10 } from 'styles/colors';
import { FlexWrapper, H1, H3, Small, TextWrapper } from 'components';

interface Props {
  percentage: string;
  text: string;
}

export const BenefitItem: React.FC<Props> = React.memo(
  ({ percentage, text }) => {
    const { isMobile } = useQuery();
    return (
      <Container alignItems="center" justifyContent="space-between">
        <FlexWrapper width="auto" alignItems="flex-end">
          <H1 color={blue}>{percentage}</H1>
          <H3 color={blue} margin="0 0 0.5rem 0.5rem">
            %
          </H3>
        </FlexWrapper>
        <TextWrapper
          margin={isMobile ? '0 0 0 1rem' : '0 0 0 1.5rem'}
          maxWidth={isMobile ? '100%' : '8.1875rem'}
        >
          <Small color={blue}>{text}</Small>
        </TextWrapper>
      </Container>
    );
  },
);

BenefitItem.displayName = 'BenefitItem';

const Container = styled(FlexWrapper)`
  max-width: 24.125rem;
  width: 100%;
  height: 11.25rem;
  padding-left: 2.5rem;
  padding-right: 2.5rem;
  border-radius: 0.625rem;
  border: 0.0625rem solid ${blue10};
  background: ${background};

  @media ${mobile} {
    align-items: center;
    height: 6.875rem;
    margin-bottom: 1.25rem;
    padding-left: 1rem;
    padding-right: 1rem;

    &:nth-child(3) {
      height: 8.5rem;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
