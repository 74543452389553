import { useMediaQuery } from 'react-responsive';

export const breakpoints = {
  xsMobile: 336,
  mobile: 480,
  tablet: 768,
  xsmDekstop: 896,
  smDekstop: 1024,
  dekstop: 1280,
  lgDekstop: 1600,
  xlDekstop: 1900,
};

export const xsMobile = '(max-width: 21em)'; //336px
export const smMobile = '(max-width: 24em)'; // 384px
export const mobile = '(max-width: 30em)'; // 480px
export const tablet = '(max-width: 48em)'; // 768px
export const xsmDesktop = '(max-width: 56rem)'; // 896px
export const smDesktop = '(max-width: 64em)'; // 1024px
export const lgDesktop = '(max-width: 80em)'; // 1280px

export const useQuery = () => ({
  isXsMobile: useMediaQuery({ query: xsMobile }),
  isMobile: useMediaQuery({ query: mobile }),
  isTablet: useMediaQuery({ query: tablet }),
  isXsmDesktop: useMediaQuery({ query: xsmDesktop }),
  isSmDesktop: useMediaQuery({ query: smDesktop }),
  isLgDekstop: useMediaQuery({ query: lgDesktop }),
});
