module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Fireflux","short_name":"Fireflux","start_url":"/","icon":"src/assets/images/favicons/favicon-32x32.png","display":"standalone","icons":[{"src":"src/assets/images/favicons/android-chrome-192x192.png","sizes":"192x192","type":"image/png"},{"src":"src/assets/images/favicons/android-chrome-256x256.png","sizes":"256x256","type":"image/png"}],"theme_color":"#F2614F","background_color":"#ffffff","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"13eddd9aa43d7ec39b391874291f9765"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
