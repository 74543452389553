import { createActionCreator } from '@reduxify/utils';

import {
  SET_CODE,
  SET_EMAIL,
  SET_ERROR_MSG,
  SET_QUIZ_ANSWERS,
  SET_SELECTED_PLAN,
  SET_SUMMARY,
} from './constants';
import { UserState } from './types';

export const setQuizAnswers = createActionCreator<UserState['quiz_answers']>(
  SET_QUIZ_ANSWERS,
);
export const setEmail = createActionCreator<UserState['email']>(SET_EMAIL);
export const setCode = createActionCreator<UserState['code']>(SET_CODE);
export const setErrorMsg = createActionCreator<UserState['error_msg']>(
  SET_ERROR_MSG,
);
export const setSelectedPlan = createActionCreator<UserState['selected_plan']>(
  SET_SELECTED_PLAN,
);
export const setSummary = createActionCreator<UserState['summary']>(
  SET_SUMMARY,
);
