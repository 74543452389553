import React from 'react';
import styled from 'styled-components';

import {
  almostWhite,
  almostWhite14,
  background,
  blue,
  blue10,
  text60,
  text70,
} from 'styles/colors';
import { mobile, useQuery } from 'styles/breakpoints';
import { Container } from '../wrappers/Container';
import { FlexWrapper } from '../wrappers/FlexWrapper';
import { H3, Regular, Svg, TextWrapper } from '..';

const WITHOUT_PULSIO_ITEMS = [
  'Hypertension',
  'Unmanageable cholesterol levels',
  'High sugar',
  'Unhealthy weight',
  'Irregular heartbeat',
];
const WITH_PULSIO_ITEMS = [
  'Lower blood pressure',
  'Manage cholesterol',
  'Regulate sugar levels',
  'Maintain healthy weight',
  'Keep cardiovascular system healthy',
];

interface Props {
  className?: string;
}

export const BuiltForYou: React.FC<Props> = React.memo(({ className }) => {
  const { isMobile, isXsmDesktop } = useQuery();

  return (
    <Container className={className}>
      <FlexWrapper
        alignItems="center"
        justifyContent="space-between"
        flexDirection={isXsmDesktop ? 'column' : 'row'}
        padding={isMobile ? null : '0 2.125rem'}
      >
        <ItemsColumn background={background}>
          <TextWrapper maxWidth={isMobile ? '100%' : '14.75rem'}>
            <H3 color={text70} padding="0 0 1.625rem">
              Life before finding Pulsio
            </H3>
          </TextWrapper>
          {WITHOUT_PULSIO_ITEMS.map(item => (
            <Item key={item} alignItems="center" borderColor={blue10}>
              <Svg
                src="icons/cross"
                width="12"
                height="13"
                viewBox="0 0 12 13"
                opacity="0.7"
                fill="none"
              />
              <Regular color={text60} padding="0 0 0 1.75rem">
                {item}
              </Regular>
            </Item>
          ))}
        </ItemsColumn>
        <ItemsColumn background={blue}>
          <TextWrapper maxWidth="15.625rem">
            <H3 color={almostWhite} padding="0 0 1.625rem">
              Life with Pulsio helps to:
            </H3>
          </TextWrapper>
          {WITH_PULSIO_ITEMS.map(item => (
            <Item key={item} alignItems="center" borderColor={almostWhite14}>
              <Svg
                src="icons/checkmark"
                width="16"
                height="12"
                viewBox="0 0 16 12"
                fill="none"
              />
              <Regular color={almostWhite} padding="0 0 0 1.75rem">
                {item}
              </Regular>
            </Item>
          ))}
        </ItemsColumn>
      </FlexWrapper>
    </Container>
  );
});

BuiltForYou.displayName = 'BuiltForYou';

const ItemsColumn = styled.div<{ background: string }>`
  max-width: 35rem;
  width: 100%;
  padding: 3.125rem 5rem 3.5rem;
  border: 0.0625rem solid ${blue10};
  border-radius: 0.625rem;
  background: ${props => props.background};

  @media ${mobile} {
    padding: 3.125rem 1.5rem 3rem;
    border-radius: 0;
  }
`;
const Item = styled(FlexWrapper)<{ borderColor: string }>`
  padding-bottom: 0.875rem;
  margin-bottom: 1.125rem;
  border-bottom: 0.0625rem solid ${props => props.borderColor};

  &:last-of-type {
    margin-bottom: 0;
  }
`;
