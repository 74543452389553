import React from 'react';
import { Helmet } from 'react-helmet';

interface Props {
  title?: string;
  description?: string;
}

export const Seo: React.FC<Props> = React.memo(
  ({ title, description, children }) => (
    <Helmet>
      {title ? <title>{title}</title> : null}
      {description ? <meta name="description" content={description} /> : null}
      {children}
    </Helmet>
  ),
);

Seo.displayName = 'Seo';
