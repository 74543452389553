import { Action, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import get from 'lodash/get';

import { Api } from '../../apis/api';
import { handleError } from '../../utils/error';

import {
  setCode,
  setEmail,
  setErrorMsg,
  setQuizAnswers,
  setSelectedPlan,
  setSummary,
} from './actions';
import { Lead, Summary, UserState } from './types';

export const updateQuizAnswers = (answers: UserState['quiz_answers']) => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => dispatch(setQuizAnswers(answers));

export const updateSummary = (data: Summary) => (
  dispatch: ThunkDispatch<{}, void, Action<UserState['code']>>,
) => dispatch(setSummary(data));

export const updateEmail = (email: UserState['email']) => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => dispatch(setEmail(email));

export const updateCode = (code: UserState['code']) => (
  dispatch: ThunkDispatch<{}, void, Action<UserState['code']>>,
) => dispatch(setCode(code));

export const updateSelectedPlan = (product: UserState['selected_plan']) => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => {
  if (product && typeof (window as any).kiloCheckout !== 'undefined') {
    const price = get(product, 'prices[0].final_price');
    const kiloProduct = new (window as any).KiloProduct(
      product.key,
      product.name,
      price,
    );
    (window as any).kiloCheckout.selectProduct(kiloProduct);
  }

  dispatch(setSelectedPlan(product));
};

export const updateErrorMsg = (errorMsg: UserState['error_msg']) => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => dispatch(setErrorMsg(errorMsg));

export const createLead = (data: Lead) => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) =>
  Api.createLead(data)
    .then(({ code }: { code: string }) => {
      dispatch(updateCode(code));
    })
    .catch((e: any) => {
      handleError(e);
      throw e;
    });

export const fetchUser = () => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) => {
  if (typeof window !== 'undefined') {
    const params = new URLSearchParams(window.location.search);
    const codeParam = window.location.pathname?.split('/')?.pop();
    const pathCode = params.get('code') || codeParam;

    if (pathCode && pathCode.length === 32) {
      Api.fetchLead(pathCode)
        .then(data => {
          const { email, code, quiz_answers } = data;

          if (email) {
            dispatch(updateEmail(email));
          }

          if (quiz_answers) {
            dispatch(updateQuizAnswers(quiz_answers));
          }

          dispatch(updateCode(code));
        })
        .catch(handleError);
    }
  }
};
