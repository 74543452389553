import React from 'react';
import { Router } from '@reach/router';

import { Route } from 'components';
import loadable from '@loadable/component';

export const Payments = loadable(() => import('pages/payments'), {
  ssr: true,
});

export const Modals = () => (
  <Router primary={false}>{Route('/:page/payments', Payments)}</Router>
);
