import { createReducer, merge, reduce, set } from '@reduxify/utils';
import { combineReducers } from 'redux';

import {
  setCode,
  setEmail,
  setErrorMsg,
  setQuizAnswers,
  setSelectedPlan,
  setSummary,
} from './actions';
import { UserState } from './types';

const quiz_answers = createReducer<UserState['quiz_answers']>(
  {},
  reduce(setQuizAnswers, merge),
);
const summary = createReducer<UserState['summary']>(
  null,
  reduce(setSummary, set),
);
const email = createReducer<UserState['email']>('', reduce(setEmail, set));
const code = createReducer<UserState['code']>('', reduce(setCode, set));
const selected_plan = createReducer<UserState['selected_plan']>(
  null,
  reduce(setSelectedPlan, set),
);
const error_msg = createReducer<UserState['error_msg']>(
  null,
  reduce(setErrorMsg, set),
);

export default combineReducers<UserState>({
  code,
  quiz_answers,
  email,
  error_msg,
  selected_plan,
  summary,
});
