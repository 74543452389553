import React from 'react';
import styled, { css } from 'styled-components';

import { Option } from 'state/funnel/types';
import { tablet, useQuery } from 'styles/breakpoints';
import { almostWhite, blue, blue10, blue50, white } from 'styles/colors';
import { FlexWrapper, Svg } from 'components';
import AlcoholGlassIcon from 'assets/images/quiz/alcohol_glass.svg';
import CigaretteIcon from 'assets/images/quiz/cigarette.svg';
import StressIcon from 'assets/images/quiz/stress.svg';
import NoneIcon from 'assets/images/quiz/none.svg';
import { Regular } from 'components/text/Regular';
import { H2 } from 'components/text/H2';

interface Props extends Option {
  isBig?: boolean;
  isMultiple?: boolean;
  onClick: () => void;
  isSelected?: boolean;
  isRadio?: boolean;
  isGenderQuestion?: boolean;
}

export const OptionCard: React.FC<Props> = ({
  label,
  custom,
  isBig,
  isMultiple,
  isSelected,
  isRadio,
  isGenderQuestion,
  ...rest
}) => {
  const { isTablet } = useQuery();
  const { icon, sublabel } = custom || {};
  const LabelComponent = isBig && !icon ? H2 : RegularLabel;
  const OptionIcon = icon ? QUIZ_ICONS[icon] : null;

  return (
    <OptionBox
      isBig={isBig}
      isMultiple={isMultiple}
      isSelected={isSelected}
      isGenderSelection={isGenderQuestion}
      alignItems="center"
      flexDirection={isBig && isMultiple && !isTablet ? 'column' : 'row'}
      justifyContent={isBig ? 'center' : isMultiple ? 'space-between' : null}
      {...rest}
    >
      {OptionIcon ? (
        <FlexWrapper
          width={isTablet ? '65px' : ''}
          justifyContent="center"
          flex="0.33"
        >
          <OptionIcon isSelected={isSelected} height={isTablet ? '24px' : ''} />
        </FlexWrapper>
      ) : null}
      <FlexWrapper
        flex={isTablet ? '1' : ''}
        alignItems={isBig ? 'center' : 'flex-start'}
        justifyContent={isBig && (!isTablet || !icon) ? 'center' : 'flex-start'}
        flexDirection={isTablet ? 'row' : 'column'}
      >
        <LabelComponent
          color={isSelected && isMultiple ? white : blue}
          textAlign={sublabel ? 'center' : null}
          margin={isTablet && sublabel ? '0 1.125rem 0 0' : '0px'}
        >
          {isRadio && (
            <OptionMarker isMultiple={isMultiple} isSelected={isSelected} />
          )}
          {label}
        </LabelComponent>
        {sublabel && <Regular color={blue}>{sublabel}</Regular>}
      </FlexWrapper>
      {isSelected && isMultiple && !icon ? (
        <Svg
          src="icons/checkmark"
          width="16"
          height="12"
          viewBox="0 0 16 12"
          fill="none"
        />
      ) : null}
    </OptionBox>
  );
};

const OptionBox = styled(FlexWrapper)<{
  isSelected?: boolean;
  isBig?: boolean;
  isMultiple?: boolean;
  isGenderSelection?: boolean;
}>`
  max-width: ${props =>
    props.isBig
      ? 17.625
      : props.isMultiple
      ? 18.125
      : props.isGenderSelection
      ? 11.5
      : 21.875}rem;
  width: 100%;
  margin: ${props =>
    props.isMultiple && props.isBig ? '0 auto 1rem auto' : '0 0 1rem'};
  padding: ${props => (props.isBig ? '3.25rem 1.5rem' : '0.65rem 1.5rem')};
  background: ${props => (props.isMultiple && props.isSelected ? blue : white)};
  border: 0.0625rem solid ${({ isSelected }) => (isSelected ? blue50 : blue10)};
  border-radius: ${props =>
    props.isMultiple || props.isGenderSelection ? '1.875rem' : '0.625rem'};
  transition: border 0.2s;
  cursor: pointer;
  -webkit-tap-highlight-color: transparent;

  @media (min-width: 767px) {
    :hover:not(.disabled):not(.selected) {
      border-color: ${blue50};
    }
  }

  @media ${tablet} {
    max-width: 100%;
    margin-bottom: 0.5rem;
    height: 50px;

    ${({ isBig }) =>
      isBig &&
      css`
        padding: 0.625rem 0.5rem;

        h2 {
          font-size: 1.5rem;
        }
      `}

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;
const quizIconStyles = ({ isSelected }: { isSelected?: boolean }) => css`
  margin-bottom: 2.625rem;

  rect,
  line,
  circle,
  path {
    stroke: ${isSelected ? almostWhite : blue};
  }

  @media ${tablet} {
    margin: 0;
  }
`;
const AlcoholGlass = styled(AlcoholGlassIcon)<{ isSelected?: boolean }>`
  ${props => quizIconStyles(props)}
`;
const Cigarette = styled(CigaretteIcon)<{ isSelected?: boolean }>`
  ${props => quizIconStyles(props)}
`;
const CoffeeCup = styled(StressIcon)<{ isSelected?: boolean }>`
  ${props => quizIconStyles(props)}
`;
const None = styled(NoneIcon)<{ isSelected?: boolean }>`
  ${props => quizIconStyles(props)}
`;

const QUIZ_ICONS = {
  alcohol_glass: AlcoholGlass,
  cigarette: Cigarette,
  stress: StressIcon,
  none: None,
};

const OptionMarker = styled.div<{ isMultiple?: boolean; isSelected?: boolean }>`
  width: 20px;
  height: 20px;
  background: ${({ isSelected }) => (isSelected ? white : 'transparent')};
  border-radius: ${({ isMultiple }) => (isMultiple ? '2px' : '50%')};
  border: ${({ isSelected }) =>
    isSelected
      ? '1px solid rgba(14, 79, 106, 1)'
      : '1px solid rgba(0, 0, 0, 0.2)'};
  margin-right: 1.2rem;
  box-sizing: border-box;
  position: relative;

  &:before {
    content: '';
    display: ${({ isSelected, isMultiple }) =>
      isSelected && isMultiple ? 'block' : 'none'};
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 5px;
    border-left: 2px solid ${blue};
    border-bottom: 2px solid ${blue};
    transform: translate(-50%, -70%) rotate(-45deg);
  }

  ${({ isSelected, isMultiple }) =>
    !isMultiple &&
    isSelected &&
    css`
      &::after {
        position: absolute;
        width: 8px;
        height: 8px;
        content: '';
        background: ${blue};
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border-radius: 50%;
      }
    `}
`;
const RegularLabel = styled(Regular)`
  display: flex;
  align-items: center;
`;
