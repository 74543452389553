export const tints = {
  black: (tint: number) => `rgba(0, 0, 0, ${tint / 10})`,
  primary: (tint: number) => `rgba(220, 255, 4, ${tint / 10})`,
  white: (tint: number) => `rgba(255, 255, 255, ${tint / 10})`,
  text: (tint: number) => `rgba(23, 22, 22, ${tint / 10})`,
  blue: (tint: number) => `rgba(14, 79, 106, ${tint / 10})`,
  almostWhite: (tint: number) => `rgba(243, 248, 254, ${tint / 10})`,
};

export const black = '#000000';
export const white = '#FFFFFF';
export const error = '#EF3E3E';
export const primary = '#F2614F';
export const yellow = '#EEB63F';
export const text = '#171616';
export const blue = '#0E4F6A';
export const red = '#F06159';
export const danger = '#FB3640';
export const almostWhite = '#F3F8FE';
export const background = '#F9F9F9';
export const text70 = tints.text(7);
export const text60 = tints.text(6);
export const text50 = tints.text(5);
export const text20 = tints.text(2);
export const text10 = tints.text(1);
export const blue80 = tints.blue(8);
export const blue70 = tints.blue(7);
export const blue60 = tints.blue(6);
export const blue50 = tints.blue(5);
export const blue30 = tints.blue(3);
export const blue10 = tints.blue(1);
export const white50 = tints.white(5);
export const white70 = tints.white(7);
export const white80 = tints.white(8);
export const almostWhite90 = tints.almostWhite(9);
export const almostWhite50 = tints.almostWhite(5);
export const almostWhite14 = tints.almostWhite(1.4);
export const black80 = tints.black(8);
export const black70 = tints.black(7);
export const black10 = tints.black(1);
