import styled from 'styled-components';

import { text } from 'styles/colors';
import { mobile } from 'styles/breakpoints';

interface Styles {
  margin?: string | null;
  padding?: string | null;
  fontSize?: string;
  fontWeight?: string | number;
  lineHeight?: string;
  color?: string;
  mobileFontSize?: string;
  mobileLineHeight?: string;
  textAlign?: string;
  zIndex?: number;
}

export const H1 = styled.h1<Styles>`
  margin: ${props => props.margin || '0px'};
  padding: ${props => props.padding || '0px'};
  font-family: ivypresto-headline, serif;
  font-size: ${props => props.fontSize || '5rem'};
  font-weight: ${props => props.fontWeight || 600};
  line-height: ${props => props.lineHeight || '5.875rem'};
  text-align: ${props => props.textAlign || 'inherit'};
  color: ${props => props.color || text};
  z-index: ${props => props.zIndex || 0};

  &.centered {
    text-align: center;
  }

  &.italic {
    font-style: italic;
  }

  @media ${mobile} {
    font-size: ${props => props.mobileFontSize || '2.75rem'};
    line-height: ${props => props.mobileLineHeight || '3.375rem'};
  }
`;
