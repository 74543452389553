import React from 'react';
import { Router } from '@reach/router';

import { Route } from 'components';
import loadable from '@loadable/component';

const FooterDefault = loadable(() => import('./FooterDefault'), {
  ssr: true,
});

export const Footers: React.FC = () => (
  <Router primary={false}>
    {Route(
      ['/', '/privacy-policy', '/terms-of-services', '/contacts', '/faq'],
      FooterDefault,
    )}
  </Router>
);
