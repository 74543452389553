import React, { useEffect, useState } from 'react';
import { handleError } from 'utils/error';

interface Props extends React.SVGProps<SVGElement> {
  src: string;
}

export const Svg: React.FC<Props> = ({ src, ...props }) => {
  const [icon, setIcon] = useState(null);

  useEffect(() => {
    const fetchIcon = async () => {
      try {
        const svg = await import(`../../assets/${src}.svg`);

        if (svg) {
          setIcon(svg.default?.(props));
        }
      } catch (err) {
        handleError(err);
      }
    };

    fetchIcon();
  }, [src]);

  if (!icon) return null;

  return icon;
};
