import React from 'react';
import styled from 'styled-components';

import { Ring } from 'react-spinners-css';

export const Loader = () => (
  <LoaderWrapper>
    <Ring color="#22bf8e" />
  </LoaderWrapper>
);

const LoaderWrapper = styled.div`
  display: flex;
  height: 30vh;
  align-items: center;
  justify-content: center;
`;
