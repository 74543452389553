import styled from 'styled-components';

import { almostWhite, blue } from 'styles/colors';

import { Button } from './Button';

export const OutlineButton = styled(Button)`
  color: ${blue};
  background: ${almostWhite};
  border: 1px solid ${blue};

  &:hover {
    color: ${almostWhite};
    background: ${blue};
  }

  &.inverted {
    color: ${almostWhite};
    background: ${blue};
    border: 1px solid ${almostWhite};

    &:hover {
      color: ${blue};
      background: ${almostWhite};
    }
  }
`;
