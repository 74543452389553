import React, { useState } from 'react';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { FlexWrapper, Logo, Tiny } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';
import { almostWhite50, black, blue, white } from 'styles/colors';
import { H2 } from 'components/text/H2';
import { Regular } from 'components/text/Regular';

interface Props {
  logoClickable?: boolean;
}

export const Header: React.FC<Props> = React.memo(
  ({ logoClickable = true }) => {
    const { isMobile } = useQuery();
    const [open, setOpen] = useState(false);
    const toggle = () => setOpen(!open);

    return (
      <FlexWrapper
        alignItems="center"
        justifyContent={isMobile ? 'center' : 'space-between'}
        padding={isMobile ? '1rem 0 2.25rem' : '2rem 3.875rem 3.125rem'}
      >
        {isMobile ? (
          <div />
        ) : (
          <Link to="/quiz">
            <Tiny>TAKE THE QUIZ</Tiny>
          </Link>
        )}
        <Logo notClickable={!logoClickable} className="centered" />
        {!isMobile ? (
          <div />
        ) : (
          <StyledBurger open={open} onClick={toggle}>
            <span>
              <div />
              <div />
              <div />
            </span>
          </StyledBurger>
        )}
        {isMobile && (
          <MobileMenu open={open}>
            <Logo notClickable={!logoClickable} className="centered" />
            <Link to="/quiz">
              <H2 color={white} margin="6.25rem auto 1.875rem">
                Take the quiz
              </H2>
            </Link>
            <Link to="/faq">
              <H2 color={white} margin="0 auto 1.875rem">
                FAQ
              </H2>
            </Link>
            <Link to="/contacts">
              <H2 color={white} margin="0 auto 2.5rem">
                Contacts
              </H2>
            </Link>
            <Link to="/privacy-policy">
              <Regular color={almostWhite50} margin="0 auto 1rem">
                Privacy policy
              </Regular>
            </Link>
            <Link to="/terms-of-services">
              <Regular color={almostWhite50} margin="0 auto 1rem">
                Terms &amp; Conditions
              </Regular>
            </Link>
          </MobileMenu>
        )}
      </FlexWrapper>
    );
  },
);

Header.displayName = 'Header';

const StyledBurger = styled.div<{ open: boolean }>`
  width: 60px;
  height: 60px;
  overflow: hidden;
  z-index: 20;
  right: ${({ open }) => (open ? '24px' : '24px')};
  display: none;
  position: ${({ open }) => (open ? 'fixed' : 'absolute')};

  span {
    width: 16px;
    height: 14px;
    overflow: hidden;
    z-index: 20;
    display: flex;
    justify-content: space-around;
    flex-flow: column nowrap;

    div {
      width: 16px;
      height: 2px;
      margin-bottom: 2px;
      background-color: ${({ open }) => (open ? white : black)};
      border-radius: 4px;
      transform-origin: 1px;
      transition: all 0.3s linear;
      &:nth-child(1) {
        transform: ${({ open }) => (open ? 'rotate(45deg)' : 'rotate(0)')};
      }
      &:nth-child(2) {
        transform: ${({ open }) =>
          open ? 'translateX(100%)' : 'translateX(0)'};
        opacity: ${({ open }) => (open ? 0 : 1)};
      }
      &:nth-child(3) {
        transform: ${({ open }) => (open ? 'rotate(-45deg)' : 'rotate(0)')};
        margin-bottom: 0;
      }
    }
  }

  @media ${mobile} {
    display: flex;
    align-items: center;
    justify-content: flex-end;
  }
`;
const MobileMenu = styled.div<{ open: boolean }>`
  height: 100vh;
  position: fixed;
  list-style: none;
  display: none;
  flex-flow: row nowrap;
  z-index: 10;
  overflow: hidden;

  @media ${mobile} {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-flow: column nowrap;
    background-color: ${blue};
    position: fixed;
    transform: ${({ open }) => (open ? 'translateX(0)' : 'translateX(100%)')};
    top: 0;
    right: 0;
    height: 100vh;
    width: 100%;
    margin: 0;
    padding: 1.25rem 1.5rem 1.5rem;
    box-sizing: border-box;
    transition: transform 0.3s ease-in-out;
  }
`;
