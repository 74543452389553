import React from 'react';
import styled from 'styled-components';

import { images } from 'utils/images';
import { mobile, useQuery } from 'styles/breakpoints';
import { almostWhite, almostWhite50, blue, blue70 } from 'styles/colors';
import { Caption, Image, OutlineButton, Regular, Small, Svg } from 'components';
import { ComponentWithCtaEvent } from 'utils/interfaces';
import { SliderSection } from './SliderSection';

const TESTIMONIALS = [
  {
    name: 'Janet W. (55)',
    photo: 'reviewer_3',
    title: 'Fixed cholesterol',
    review:
      'My blood pressure is now within normal range. All my cholesterol level labs came back normal so I give 5 stars, although I also take Red Yeast Rice along with Pulsio..my cholesterol was 198 and LDL 115. Now cholesterol 135 and LDL 74 so its a combination to both and I am thankful. Product is easy to swallow and no side effects.',
  },
  {
    name: 'Claire J. (62)',
    photo: 'reviewer_4',
    title: 'Helped',
    is4Start: true,
    review:
      'I\'ve always have had high cholesterol, even with watching my diet. My family has naturally high cholesterol. Many of my siblings also have xanthomas, which are bumps on the eyelids when the cholesterol is off the charts. While vacationing in the FL Keys, I found a store that had excellent fried chicken so we went overboard. Before I knew it I had the xanthomas I thought I wouldn\'t get. I had two, one in the middle of my eyelid, the other in the corner, but they were visible... and made worse with makeup.... starting taking sachet of this a day and I\'m not even finished with the box and the xanthomas are pretty much gone...just a tiny one in the corner...this is the only thing I did. In my area where I live, we don\'t have fried chicken that tastes that good so I\'m off of it for awhile.... even so I will continue to take these since it was easy and I had no side effects at all.',
  },
  {
    name: 'Kevin J. (45)',
    photo: 'reviewer_1',
    title: 'Peace of mind',
    review:
      'I gave this product 5 stars as a few months ago I went to dr. (year physical) and bloodwork came back, high cholesterol. She wanted to start me on prescription meds, but I\'ve researched and found this, I have an appointment for bloodwork in October, and I pray this natural supplement is working because I hate the idea of taking a lifelong prescription. If you’re wondering why I gave this 5 stars, it’s because it’s wonderful we have a natural supplement available with no side effects unlike the prescription my sister takes for her cholesterol (sore all the time).',
  },
  {
    name: 'Albert B. (58)',
    photo: 'reviewer_2',
    title: 'Blood pressure under control',
    review:
      'I am the husband of the Pulsio subscriber, and this supplement was for me. I have tried several other things to lower my blood pressure naturally, and nothing has worked. I came across this one and decided to give it a try. After 2 weeks, my blood pressure went from around 150/90 on average to 130/76 on average. That\'s just after 2 weeks.',
  },
  {
    name: 'Simone B. (61)',
    photo: 'reviewer_5',
    title: 'My cardiologist approved',
    is4Start: true,
    review:
      'I bought these to try and help clear partially blocked arteries in my heart which limits my physical exertion limits. My condition was so bad that I get short of breath just walking a few hundred feet. I took the bottle with me to my heart specialist before I took it. He said it was OK to take these. I am also trying to limit my intake of fats and increase my statin drug dosage. I am a little bit better now, after about three weeks. I can walk about 1/2 mile without getting short of breath, so something is working. I love the results of this product. I am onto my third box now, walking a mile or more with no problems. This is a dramatic improvement for me. ',
  },
  {
    name: 'Jenna M. (50)',
    photo: 'reviewer_6',
    title: 'Good product',
    review:
      'I have been taking Pulsio supplement for a couple of weeks now and I feel my energy level has improved. Next week, I am going in for some new labs to see if my cholesterol and triglycerides levels have changed yet, but overall, I think this is a good product.',
  },
  {
    name: 'Stephen R. (74)',
    photo: 'reviewer_7',
    title: 'Actually works',
    review:
      'This is my third time repurchasing. Within a couple of weeks after using it, my blood pressure was hovering close to 140/90 now runs about 118/73 steadily. I\'m very thankful for it and highly recommend it.',
  },
  {
    name: 'Joyce D. (48)',
    photo: 'reviewer_8',
    title: 'Great product',
    is4Start: true,
    review:
      'OMG it worked immediately! I bought these because my cholesterol was borderline high, and I immediately got an unexpected benefit. I\'ve had asthma all my life but for the last 2 years I\'ve been unable to catch my breath even with asthma treatment. I\'ve had countless appointments and tests to try to figure out the problem with no solid answers, except I have a slight heart murmur (apparently not bad enough to cause my breathing issue. The week after taking this, I\'ve started breathing better than I have since I can remember. I can\'t even describe how clear my lungs feel! I don\'t know how, but I haven\'t changed my diet or any other supplements, so it must be this! I\'m definitely ordering again!',
  },
  {
    name: 'Stephanie D. (44)',
    photo: 'reviewer_9',
    title: 'Thank you Pulsio',
    review:
      'I was to have a heart cath for suspected blockage in a few weeks before buying this product, and when it finally happened, the test was flawless, and all arteries were perfectly clear. Was it due to this? Who knows but I believe it was and that is all that matters to me. Thank you Pulsio !',
  },
  {
    name: 'Christy S. (60)',
    photo: 'reviewer_10',
    title: 'Great present for loved one',
    is4Start: true,
    review:
      'I bought this for my husband because he has problems with high cholesterol and high blood pressure. He is taking prescribed meds. We thought this daily vitamin might help improve his everyday well-being. He\'s been taking it daily for 2 weeks and says he feels more energy and more motivation during his daily activities. He\'s enjoying it and plan on buying more. Thanks',
  },
];

interface Props extends ComponentWithCtaEvent {
  title: React.ReactNode;
  description?: string;
  ctaText?: string;
}

export const Testimonials: React.FC<Props> = ({
  title,
  description,
  onCtaClick,
  ctaText,
}) => {
  const { isMobile } = useQuery();

  return (
    <Background>
      <Container>
        {title}
        {description ? (
          <Caption
            color={almostWhite}
            textAlign="center"
            padding={isMobile ? '0.75rem 0 1.875rem' : '1.75rem 0 4.5rem'}
          >
            Pulsio&apos;s reviews and results.
          </Caption>
        ) : null}
        <SliderSection
          infinite={true}
          dots={isMobile}
          centerMode
          centerPadding="14px"
          slidesToScroll={1}
          autoplay={!isMobile}
          speed={2000}
          autoplaySpeed={2000}
          mobileFirst
          pauseOnHover
          variableWidth
        >
          {TESTIMONIALS.map(
            ({ name, photo, title, review, is4Start }, index) => (
              <TestimonialItem key={index}>
                <TestimonialItemWrapper>
                  <Svg
                    src={`icons/stars_${is4Start ? '4' : '5'}`}
                    width="84"
                    height="14"
                    viewBox="0 0 84 14"
                    fill="none"
                  />
                  <Caption color={blue} padding="1.125rem 0 0.375rem">
                    {title}
                  </Caption>
                  <Small color={blue70}>{review}</Small>
                </TestimonialItemWrapper>
                <TestimonialItemFooter>
                  <Image
                    src={photo}
                    width="3.25rem"
                    margin="0 auto"
                    borderRadius="100%"
                  />
                  <Regular color={almostWhite}>{name}</Regular>
                  <Regular color={almostWhite50}>Verified buyer</Regular>
                </TestimonialItemFooter>
              </TestimonialItem>
            ),
          )}
        </SliderSection>
        <OutlineButton
          maxWidth={isMobile ? 'calc(100% - 3rem)' : '9.625rem'}
          margin={isMobile ? '2.5rem auto 0' : '1.5rem auto 0'}
          className="inverted"
          onClick={onCtaClick}
        >
          {ctaText || 'TAKE THE QUIZ'}
        </OutlineButton>
      </Container>
    </Background>
  );
};

const Background = styled.div`
  position: relative;
  background-image: url(${images.testimonials_background});
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  background-color: ${almostWhite};
`;
const Container = styled.div`
  padding: 6.875rem 0;

  @media ${mobile} {
    padding: 3.625rem 0 4.875rem;
  }
`;
const TestimonialItem = styled.div`
  position: relative;
  max-width: 19.625rem;
  width: 100%;
  padding: 0 1rem;
`;
const TestimonialItemWrapper = styled.div`
  padding: 2.125rem 1.9375rem 4.125rem 2.125rem;
  border-radius: 0.625rem;
  background: ${almostWhite};
  @media ${mobile} {
    min-height: 350px;
  }
`;
const TestimonialItemFooter = styled.div`
  text-align: center;
  transform: translateY(-2rem);
`;
