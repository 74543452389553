import React, { useEffect } from 'react';
import { ParallaxController, withController } from 'react-scroll-parallax';

/**
 * Use this component whenever parallax components stucks or acts weird.
 * Read more https://github.com/jscottsmith/react-scroll-parallax#example-usage-of-context
 */
interface Props {
  parallaxController: ParallaxController;
}

const ParallaxCacheComponent: React.FC<Props> = React.memo(
  ({ parallaxController }) => {
    const handler = () => parallaxController.update();

    useEffect(() => {
      if (typeof window !== 'undefined' && parallaxController) {
        window.addEventListener('scroll', handler);
        return () => window.removeEventListener('scroll', handler);
      }
    }, [parallaxController]);

    return null;
  },
);

ParallaxCacheComponent.displayName = 'ParallaxCache';

export const ParallaxCache = withController(ParallaxCacheComponent);
