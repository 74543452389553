import { createActionCreator } from '@reduxify/utils';

import { SET_EXPERIMENTS, SET_PRODUCTS, SET_QUESTIONS } from './constants';
import { IFunnelState } from './types';

export const setProducts = createActionCreator<IFunnelState['products']>(
  SET_PRODUCTS,
);
export const setQuestions = createActionCreator<IFunnelState['questions']>(
  SET_QUESTIONS,
);
export const setExperiments = createActionCreator<IFunnelState['experiments']>(
  SET_EXPERIMENTS,
);
