import styled from 'styled-components';

import { text } from 'styles/colors';

interface Styles {
  margin?: string;
  padding?: string;
  fontSize?: string;
  fontWeight?: string | number;
  lineHeight?: string;
  color?: string;
  textAlign?: string;
  mobileFontSize?: string;
  mobileLineHeight?: string;
}

export const Caption = styled.p<Styles>`
  margin: ${props => props.margin || '0px'};
  padding: ${props => props.padding || '0px'};
  font-family: acumin-pro, sans-serif;
  font-size: ${props => props.fontSize || '1.25rem'};
  font-weight: ${props => props.fontWeight || 'normal'};
  line-height: ${props => props.lineHeight || '1.875rem'};
  color: ${props => props.color || text};
  text-align: ${props => props.textAlign || 'inherit'};
`;
