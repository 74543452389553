import styled from 'styled-components';

interface Props {
  columns: number;
  gap?: string;
  margin?: string;
  padding?: string;
  justifyItems?: string | null;
  alignItems?: string;
  maxWidth?: string;
}

export const GridWrapper = styled.div<Props>`
  display: grid;
  grid-template-columns: ${props => `repeat(${props.columns}, 1fr)`};
  grid-gap: ${props => props.gap || '1rem'};
  justify-items: ${props => props.justifyItems || ''};
  align-items: ${props => props.alignItems || 'stretch'};
  margin: ${props => props.margin || ''};
  padding: ${props => props.padding || ''};
  max-width: ${props => props.maxWidth || ''};
`;
