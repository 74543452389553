import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { FlexWrapper, Regular } from 'components';
import { black } from 'styles/colors';
import { tablet } from 'styles/breakpoints';
import { Svg } from 'components/images/Svg';

interface Props {
  title: string;
  color?: string;
  children: React.ReactElement;
}

export const Accordion = ({ color, title, children }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const [maxHeight, setMaxHeight] = useState(0);
  const childRef = useRef<HTMLElement>();

  useEffect(() => {
    setMaxHeight(expanded ? childRef.current?.scrollHeight || 0 : 0);
  }, [expanded]);

  const handleExpand = () => {
    setExpanded(prev => !prev);
  };

  return (
    <AccordionWrapper color={color || black}>
      <AccordionTitle onClick={handleExpand} expanded={expanded}>
        <AccordionTitleText fontSize="1.25rem" textAlign="left">
          {title}
        </AccordionTitleText>
        <Svg
          src={`icons/${expanded ? 'minus' : 'plus'}`}
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        />
      </AccordionTitle>
      <AccordionContentWrap expanded={expanded}>
        <AccordionInner maxHeight={maxHeight}>
          {React.cloneElement(children, { ref: childRef })}
        </AccordionInner>
      </AccordionContentWrap>
    </AccordionWrapper>
  );
};

const AccordionWrapper = styled(props => <FlexWrapper {...props} />)<{
  color: string;
}>`
  flex-direction: column;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  &:last-of-type {
    border-bottom: none;
  }
`;

const AccordionTitle = styled.button<{ expanded?: boolean }>`
  display: flex;
  align-items: center;
  background: transparent;
  outline: none;
  border: none;
  padding: 1.5rem;
  cursor: pointer;
  position: relative;
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const AccordionTitleText = styled(Regular)`
  max-width: 95%;
  @media ${tablet} {
    max-width: 85%;
  }
`;
const AccordionContentWrap = styled.div<{ expanded?: boolean }>`
  overflow: ${props => (props.expanded ? 'auto' : 'hidden')};
  background: transparent;
  ::-webkit-scrollbar {
    width: 0;
  }
`;

const AccordionInner = styled.div<{ maxHeight: number }>`
  transition: max-height 0.6s ease;
  max-height: calc(${props => props.maxHeight}px);
  opacity: 0.5;
  max-width: 42rem;
`;
