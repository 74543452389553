import styled from 'styled-components';

interface Props {
  display?: string;
  width?: string;
  maxWidth?: string;
  height?: string;
  padding?: string;
  margin?: string;
  textAlign?: string;
  justifyContent?: string;
  flexDirection?: string;
}

export const TextWrapper = styled.div<Props>`
  position: relative;
  display: ${props => props.display || 'block'};
  width: ${props => props.width || 'unset'};
  max-width: ${props => props.maxWidth || '35rem'};
  height: ${props => props.height || ''};
  padding: ${props => props.padding || '0'};
  margin: ${props => props.margin || '0'};
  text-align: ${props => props.textAlign || 'inherit'};
  justifycontent: ${props => props.justifyContent || ''};
  flexdirection: ${props => props.flexDirection || ''};
`;
