import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';

import { Api } from '../../apis/api';
import { handleError } from '../../utils/error';

import { setProjectConfig } from './actions';
import { IConfigState } from './types';

export const fetchProjectConfig = () => (
  dispatch: ThunkDispatch<{}, void, AnyAction>,
) =>
  Api.fetchProjectConfig()
    .then((data: IConfigState['projectConfig']) =>
      dispatch(setProjectConfig(data)),
    )
    .catch(handleError);
