import React from 'react';

import { useQuery } from 'styles/breakpoints';

import { Container } from '../wrappers/Container';
import { FlexWrapper } from '../wrappers/FlexWrapper';
import { Svg } from 'components/images/Svg';
import { Image } from 'components/images/Image';
import { H2 } from 'components/text/H2';
import { PrimaryButton } from 'components/buttons/PrimaryButton';

interface Props {
  className?: string;
  onClick: () => void;
  ctaTitle?: string;
}

export const Featured: React.FC<Props> = React.memo(
  ({ className, onClick, ctaTitle }) => {
    const { isMobile } = useQuery();

    return (
      <Container className={className}>
        <Svg
          src="icons/leaves_2"
          width="52"
          height="36"
          viewBox="0 0 52 36"
          fill="none"
          style={{ display: 'block', margin: '0 auto' }}
        />
        <H2
          textAlign="center"
          margin={isMobile ? '0.5rem auto 2rem' : '0.5rem auto 3.75rem'}
        >
          As seen in
        </H2>
        {isMobile ? (
          <FlexWrapper padding="0 2rem" flexDirection="column">
            <FlexWrapper alignItems="center" justifyContent="space-between">
              <Image src="business_insider" opacity="0.7" />
              <Image src="healthline" />
            </FlexWrapper>
            <Image width="80%" src="ny_times" margin="2rem auto" />
            <FlexWrapper alignItems="center" justifyContent="space-between">
              <Image src="harvard_medical_school" />
              <Image src="mens_health" />
            </FlexWrapper>
          </FlexWrapper>
        ) : (
          <FlexWrapper alignItems="center" justifyContent="space-between">
            <Image src="business_insider" opacity="0.7" />
            <Image src="healthline" />
            <Image src="ny_times" height="2rem" width="auto" />
            <Image src="harvard_medical_school" />
            <Image src="mens_health" />
          </FlexWrapper>
        )}
        <PrimaryButton
          maxWidth={isMobile ? '100%' : '11.125rem'}
          margin={isMobile ? '3rem auto 0' : '4.75rem auto 0'}
          onClick={onClick}
        >
          {ctaTitle || 'TAKE THE QUIZ'}
        </PrimaryButton>
      </Container>
    );
  },
);

Featured.displayName = 'Featured';
