import { Regular } from 'components/text/Regular';
import React from 'react';
import styled from 'styled-components';

import { blue, blue10 } from 'styles/colors';

export interface InputDefaultProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  placeholder?: string;
  type?: 'text' | 'tel' | 'email' | 'number';
  name: string;
  label?: string;
  className?: string;
  min?: number;
  max?: number;
  value?: string;
  onChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
  pattern?: string;
  maxLength?: number;
  inputBackgroundColor?: string;
  margin?: string;
}

export const Input: React.FC<InputDefaultProps> = ({
  placeholder = '',
  type = 'text',
  name,
  label,
  className,
  min = 1,
  max,
  value,
  onChange,
  pattern,
  maxLength,
  inputBackgroundColor,
  margin,
}) => (
  <InputComp
    inputBackgroundColor={inputBackgroundColor}
    className={className}
    margin={margin}
  >
    <Field
      value={value}
      onChange={onChange}
      onInvalid={
        type === 'email'
          ? (e: React.ChangeEvent<HTMLInputElement>) =>
              e.target.setCustomValidity('Enter an email address')
          : () => {}
      }
      onInput={
        type === 'email'
          ? (e: React.ChangeEvent<HTMLInputElement>) =>
              e.target.setCustomValidity('')
          : () => {}
      }
      placeholder={placeholder}
      type={type}
      name={name}
      required={true}
      min={min}
      max={max}
      maxLength={maxLength}
      pattern={
        type === 'email'
          ? '[a-zA-Z0-9.+-_]{1,}@[a-zA-Z.-]{2,}[.]{1}[a-zA-Z]{2,}'
          : pattern
      }
      inputBackgroundColor={inputBackgroundColor}
    />
    {label && (
      <Label color={blue} padding="0 0 0 0.5rem">
        {label}
      </Label>
    )}
  </InputComp>
);

const Label = styled(Regular)`
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
`;

const InputComp = styled.div<{
  inputBackgroundColor?: string;
  margin?: string;
}>`
  margin: ${props => props.margin || '0 auto 1rem auto'};
  display: flex;
  font-size: 1rem;
  font-family: acumin-pro, sans-serif;
  position: relative;

  &.inline__left {
    flex: 1;
    margin-right: 0.5rem;
  }

  &.inline__right {
    flex: 1;
    margin-left: 0.5rem;
  }
`;

const Field = styled.input<{ inputBackgroundColor?: string }>`
  flex: 1;
  width: 100%;
  padding: 0;
  margin: 0;
  font-size: inherit;
  color: ${blue};
  border: none;
  outline: none;
  border-radius: 1.875rem;
  border: 1px solid ${blue10};
  background: ${props => props.inputBackgroundColor || ''};
  padding: 1rem 2.5rem 1rem 1.5rem;

  &::placeholder {
    color: ${blue};
    opacity: 0.5;
  }
`;
