import React from 'react';
import styled from 'styled-components';

import { FlexWrapper } from '../wrappers/FlexWrapper';

interface Props {
  size: number;
  color: string;
  margin?: string;
  border?: string;
  className?: string;
  position?: string;
  onMouseEnter?: () => void;
  onMouseLeave?: () => void;
  onClick?: () => void;
}

export const Circle: React.FC<Props> = ({
  children,
  size,
  color,
  margin,
  className,
  onMouseEnter,
  onMouseLeave,
  onClick,
  ...rest
}) => (
  <Container
    alignItems="center"
    justifyContent="center"
    size={size}
    color={color}
    margin={margin}
    className={className}
    onMouseEnter={onMouseEnter}
    onMouseLeave={onMouseLeave}
    onClick={onClick}
    {...rest}
  >
    {children}
  </Container>
);

const Container = styled(FlexWrapper)<Props>`
  position: ${props => props.position || 'relative'};
  width: ${props => props.size}rem;
  height: ${props => props.size}rem;
  margin: ${props => props.margin || ''};
  border-radius: ${props => props.size / 2}rem;
  background: ${props => props.color};
  border: ${props => props.border};
`;
